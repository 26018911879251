export const getRandomValue = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomTwoValues = (value1, value2) => {
  return Math.random() < 0.5 ? value1 : value2;
};

export const roundUpNearest10 = (num) => {
  return Math.ceil(num / 10) * 10;
};

export const roundDownNearest10 = (num) => {
  return Math.floor(num / 10) * 10;
};

export const generateRangeArr = (start, end, interval) => {
  const rStart = roundDownNearest10(start);
  const rEnd = roundUpNearest10(end);
  const ticks = (rEnd - rStart) / interval;
  let rangerArr = Array(Math.round(ticks) + 1).fill(0);
  rangerArr = rangerArr.map((val, index) => {
    return rStart + index * interval;
  });
  return rangerArr;
};

export const msToTime = (s) => {
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;

  return pad(mins) + ":" + pad(secs) + "." + pad(ms, 3);
};
