import React from "react";
import { max, extent } from "d3-array";
import { Group } from "@visx/group";
import { AreaClosed } from "@visx/shape";
import { Axis } from "@visx/axis";
import { LinearGradient } from "@visx/gradient";
import { curveMonotoneX } from "@visx/curve";
import { LineSubject } from "@visx/annotation";

import { getTurns, getSectors } from "../../utils/constants";
import { color } from "d3";



export default function AreaChart({
  data,
  trackName,
  gradientColor,
  sectorData,
  width,
  margin,
  xScale,
  yScale,
  top,
  left,
  children,
  getDistance,
  getSpeed,
}) {
  //TODO: Fix blank screen
  let tickValues;
  if (trackName) {
    const turns = getTurns(trackName);
    const sectors = sectorData;
    tickValues = [...turns, ...sectors];
  } else {
    tickValues = [];
  }

  if (width < 10) {
    return null;
  }

  const tickFormat = (value, tickValues) => {
    const turnIndex = tickValues.findIndex((d) => d === value);
  
    let finalIndex;
    if (turnIndex !== -1) {
      finalIndex = turnIndex + 1;
    } else {
      finalIndex = 0;
    }

      let tickFormattedVal;

      if(sectorData.includes(value)){
        tickFormattedVal = `S${sectorData.findIndex((d)=> d === value) + 2}`;
      } else {
        tickFormattedVal = `T${finalIndex}`;
      }

    return tickFormattedVal;
  };

  const renderSectors = () => {
    let element;
    if (sectorData && sectorData.length > 0) {
      element = sectorData.map((sector) => {
        return (
          <>
            <LineSubject
              min={0}
              max={50}
              x={xScale(sector)}
              stroke={"green"}
              strokeWidth={2}
            />
          </>
        );
      });
    } else {
      element = null;
    }
    return element;
  };

  return (
    <Group left={left || margin.left} top={top || margin.top}>
      <LinearGradient
        id="gradient"
        from={gradientColor}
        fromOpacity={0.5}
        to={gradientColor}
        toOpacity={0.2}
      />
      <AreaClosed
        data={data}
        x={(d) => xScale(getDistance(d)) || 0}
        y={(d) => yScale(getSpeed(d)) || 0}
        yScale={yScale}
        strokeWidth={1}
        stroke="url(#gradient)"
        fill="url(#gradient)"
        curve={curveMonotoneX}
      />
      <Axis
        hideAxisLine
        key={`axis-1`}
        orientation={"top"}
        top={28}
        tickLineProps={{
          stroke: "white",
          opacity: 0.6,
        }}
        tickValues={tickValues}
        tickFormat={(val) => tickFormat(val, tickValues)}
        tickLabelProps={() => {
          return {
            y: -14,
            opacity: 0.6,
            fill: "white",
            fontSize: 10,
            textAnchor: "middle",
          };
        }}
        scale={xScale}
      />
      {renderSectors()}
      {children}
    </Group>
  );
}
