import React, { memo, useMemo, useState, useLayoutEffect } from "react";
import { useEventEmitter } from "@visx/xychart";
import * as d3 from "d3";

const brakeColorScale = d3.scaleLinear(
  [0, 50, 100],
  ["#e08181", "#f73434", "#ff0000"]
);
const gasColorScale = d3.scaleLinear(
  [0, 50, 100],
  ["#72b972", "#47cb47", "#0bd20b"]
);

const LineChartD3Zoom = ({ id, data, height = 400, width = 400 }) => {
  const [currentDataIndex, setCurrentDataIndex] = useState(0);

  useEventEmitter(
    "pointermove",
    (e) => {
      setCurrentDataIndex(e.event.index);
    },
    ["brake1", "throttle1"]
  );

  const margin = { top: 8, right: 8, bottom: 8, left: 8 };

  const boundedWidth = width - margin.left - margin.right;
  const boundedHeight = height - margin.top - margin.bottom;

  const xAccessor = (d) => d?.carCoordinates[2];
  const yAccessor = (d) => d?.carCoordinates[0];

  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain(d3.extent(data, yAccessor))
      .range([boundedHeight, 0]);
  }, [data]);

  const xScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain(d3.extent(data, xAccessor))
      .range([0, boundedWidth]);
  }, [data]);

  let zoom = d3
    .zoom()
    .scaleExtent([1, 10])
    .translateExtent([
      [0, 0],
      [boundedWidth, boundedHeight],
    ])
    .extent([
      [0, 0],
      [boundedWidth, boundedHeight],
    ]);

  zoom.on("zoom", ({ transform }) => {
    const group = d3.select(`#${id}_tcar_group`);
    group.attr("transform", transform);
  });

  //TODO: Tooltip change change zoom
  useLayoutEffect(() => {
    const svg = d3.select(`#${id}_track_car_details`);

    const x = xScale(xAccessor(data[currentDataIndex]));
    const y = yScale(yAccessor(data[currentDataIndex]));

    svg
      .transition()
      .duration(500)
      .call(
        zoom.transform,
        d3.zoomIdentity
          .translate(width / 2, height / 2)
          .scale(8)
          .translate(-x, -y)
      );
  }, [currentDataIndex]);

  useLayoutEffect(() => {
    const group = d3.select(`#${id}_tcar_group`);
    const splittedData = quads(newSamples(data));
    group
      .selectAll("path")
      .data(splittedData)
      .enter()
      .append("path")
      .attr("fill", (d) => {
        const { detail } = d;
        const [gas, brake] = detail;
        let color;
        if (brake === 0 && gas === 0) {
          color = "white";
        } else if (brake > 0) {
          color = brakeColorScale(brake);
        } else if (gas > 0) {
          color = gasColorScale(gas);
        } else {
          color = "green";
        }
        return color;
      })
      .attr("stroke", (d) => {
        const { detail } = d;
        const [gas, brake] = detail;
        let color;
        if (brake === 0 && gas === 0) {
          color = "white";
        } else if (brake > 0) {
          color = brakeColorScale(brake);
        } else if (gas > 0) {
          color = gasColorScale(gas);
        } else {
          color = "green";
        }
        return color;
      })
      .attr("d", function (d) {
        return lineJoin(d[0], d[1], d[2], d[3], 8);
      });

    // Sample the SVG path uniformly with the specified precision.
    function newSamples(data) {
      return data.map((d) => {
        return [xScale(xAccessor(d)), yScale(yAccessor(d)), d.gas, d.brake];
      });
    }

    // Compute quads of adjacent points [p0, p1, p2, p3].
    function quads(points) {
      return d3.range(points.length - 1).map(function (i) {
        var a = [points[i - 1], points[i], points[i + 1], points[i + 2]];
        a.detail = [points[i][2], points[i][3]];
        return a;
      });
    }

    // Compute stroke outline for segment p12.
    function lineJoin(p0, p1, p2, p3, width) {
      var u12 = perp(p1, p2),
        r = width / 8,
        a = [p1[0] + u12[0] * r, p1[1] + u12[1] * r],
        b = [p2[0] + u12[0] * r, p2[1] + u12[1] * r],
        c = [p2[0] - u12[0] * r, p2[1] - u12[1] * r],
        d = [p1[0] - u12[0] * r, p1[1] - u12[1] * r];

      if (p0) {
        // clip ad and dc using average of u01 and u12
        var u01 = perp(p0, p1),
          e = [p1[0] + u01[0] + u12[0], p1[1] + u01[1] + u12[1]];
        a = lineIntersect(p1, e, a, b);
        d = lineIntersect(p1, e, d, c);
      }

      if (p3) {
        // clip ab and dc using average of u12 and u23
        var u23 = perp(p2, p3),
          e = [p2[0] + u23[0] + u12[0], p2[1] + u23[1] + u12[1]];
        b = lineIntersect(p2, e, a, b);
        c = lineIntersect(p2, e, d, c);
      }

      return "M" + a + "L" + b + " " + c + " " + d + "Z";
    }

    // Compute intersection of two infinite lines ab and cd.
    function lineIntersect(a, b, c, d) {
      var x1 = c[0],
        x3 = a[0],
        x21 = d[0] - x1,
        x43 = b[0] - x3,
        y1 = c[1],
        y3 = a[1],
        y21 = d[1] - y1,
        y43 = b[1] - y3,
        ua = (x43 * (y1 - y3) - y43 * (x1 - x3)) / (y43 * x21 - x43 * y21);
      return [x1 + ua * x21, y1 + ua * y21];
    }

    // Compute unit vector perpendicular to p01.
    function perp(p0, p1) {
      var u01x = p0[1] - p1[1],
        u01y = p1[0] - p0[0],
        u01d = Math.sqrt(u01x * u01x + u01y * u01y);
      return [u01x / u01d, u01y / u01d];
    }
  }, []);

  const drawPath = () => {
    const lineGenerator = d3
      .line()
      .x((d) => {
        return xScale(xAccessor(d));
      })
      .y((d) => yScale(yAccessor(d)));

    const linePath = lineGenerator(data);

    return (
      <path
        id="monza_track_and_car"
        d={linePath}
        fill="none"
        stroke="black"
        strokeWidth={10}
      />
    );
  };

  // const drawListenerRect = () => {
  //   return (
  //     <rect
  //       fill="green"
  //       stroke="black"
  //       width={boundedWidth}
  //       height={boundedHeight}
  //     />
  //   );
  // };

  // const handleZoomIn = () => {
  //   const svg = d3.select("#track_car_details");
  //   svg.transition().call(zoom.scaleBy, 2);
  // };

  // const handleZoomOut = () => {
  //   const svg = d3.select("#track_car_details");
  //   svg.transition().call(zoom.scaleBy, 0.5);
  // };

  // const handleZoomReset = () => {
  //   const svg = d3.select("#track_car_details");
  //   svg
  //     .transition()
  //     .duration(750)
  //     .call(
  //       zoom.transform,
  //       d3.zoomIdentity,
  //       d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
  //     );
  // };

  return (
    <>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        id={`${id}_track_car_details`}
        width={width}
        height={height}
        fill="black"
        style={{
          background: "black",
          borderRadius: "8px",
        }}
      >
        <g
          id={`${id}_tcar_group`}
          width={boundedWidth}
          height={boundedHeight}
          transform={`translate(${[margin.left, margin.top].join(",")})`}
        >
          {drawPath()}
          {/* {drawListenerRect()} */}
        </g>
      </svg>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <button onClick={handleZoomIn} type="button">
          Zoom In
        </button>
        <button onClick={handleZoomOut} type="button">
          Zoom Out
        </button>
        <button onClick={handleZoomReset} type="button">
          Reset
        </button>
      </div> */}
    </>
  );
};

export default memo(LineChartD3Zoom);
