import React, {
  useRef,
  useMemo,
  memo,
  useEffect,
  useCallback,
  useState,
} from "react";
import { scaleLinear } from "@visx/scale";
import { Brush } from "@visx/brush";
import BaseBrush from "@visx/brush/lib/BaseBrush";
import { max, extent } from "d3-array";
import AreaChart from "../AreaChart";
import { withParentSize } from "@visx/responsive";
import BrushHandle from "./Handle";

// accessors
const getDistance = (d) => d?.distance || 0;
const getSpeed = (d) => d?.speed || 0;

const selectedBrushStyle = {
  fill: "green",
  fillOpacity: 0.5,
  stroke: "green",
  strokeWidth: 1,
  strokeOpacity: 0.8,
};

export const background2 = "white";
export const accentColor = "green";

function BrushChart({
  data,
  trackName,
  width,
  height,
  onBrushChange,
  sectorData,
  brushDomain,
  margin = {
    top: 0,
    left: 16,
    bottom: 0,
    right: 16,
  },
}) {
  const brushMargin = { top: 0, bottom: 0, left: 0, right: 0 };
  const boundedHeight = height - margin.top - margin.bottom;
  const boundedWidth = width - margin.left - margin.right;

  const brushDistanceScale = useMemo(
    () =>
      scaleLinear({
        range: [0, boundedWidth],
        domain: extent(data, getDistance),
      }),
    [boundedWidth]
  );

  const brushSpeedScale = useMemo(
    () =>
      scaleLinear({
        range: [boundedHeight, 0],
        domain: [0, max(data, getSpeed) || 0],
        nice: true,
      }),
    [boundedHeight]
  );

  const initialBrushPosition = useMemo(() => {
    let pixelPosition;
    if (brushDomain.start.x <= 0 && brushDomain.end.x <= 0) {
      pixelPosition = {
        start: { x: 0 },
        end: { x: 500 },
      };
    } else {
      pixelPosition = {
        start: { x: brushDistanceScale(brushDomain.start.x) },
        end: { x: brushDistanceScale(brushDomain.end.x) },
      };
    }

    return pixelPosition;
  }, [brushDomain]);

  return (
    <svg width={width} height={height}>
      <AreaChart
        trackName={trackName}
        data={data}
        sectorData={sectorData}
        width={width}
        yMax={boundedWidth}
        xScale={brushDistanceScale}
        yScale={brushSpeedScale}
        margin={margin}
        gradientColor={background2}
        getDistance={getDistance}
        getSpeed={getSpeed}
      >
        <Brush
          xScale={brushDistanceScale}
          yScale={brushSpeedScale}
          width={boundedWidth}
          height={boundedHeight}
          margin={brushMargin}
          handleSize={8}
          resizeTriggerAreas={["left", "right"]}
          initialBrushPosition={initialBrushPosition}
          onChange={onBrushChange}
          selectedBoxStyle={selectedBrushStyle}
          useWindowMoveEvents
          renderBrushHandle={(props) => <BrushHandle {...props} />}
        />
      </AreaChart>
    </svg>
  );
}

export default memo(BrushChart);
