import React, { useState, useEffect } from "react";
import { useSpring, animated, config } from "react-spring";

const useAnimatedPath = ({ toggle, delay }) => {
  const [length, setLength] = useState(null);
  const animatedStyle = useSpring({
    strokeDashoffset: toggle ? 0 : length,
    strokeDasharray: length,
    delay,
    config: config.medium,
  });

  return {
    style: animatedStyle,
    ref: (ref) => {
      if (ref) {
        setLength(ref.getTotalLength());
      }
    },
  };
};

const EyePupilSection = (props) => {
  const { toggle } = props;
  const animatedStyle = useSpring({
    rx: toggle ? 275 : 250,
    ry: toggle ? 275 : 250,
    config: config.medium,
  });
  return <animated.ellipse {...props} {...animatedStyle} />;
};

const LineChartSection = ({ toggle, d, color, stroke }) => {
  const animatedStrokeProps = useAnimatedPath({ toggle });
  const animatedFillStyle = useSpring({
    fill: toggle ? color : "#fff",
    delay: 500,
    config: config.medium,
  });

  return (
    <animated.path
      stroke={stroke}
      strokeWidth="2"
      d={d}
      {...animatedStrokeProps}
      style={{ ...animatedStrokeProps.style, ...animatedFillStyle }}
    />
  );
};

function Logo({ style, logoToggle }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="60"
      viewBox="0 0 200 100"
      {...style}
    >
      <path
        fill="grey"
        d="M104.657 14.087c-24.289 0-47.228 11.324-64.823 32.094 17.595 20.796 40.534 32.093 64.823 32.093s47.227-11.403 64.822-32.093c-17.595-20.796-40.534-32.094-64.822-32.094zm0 59.16c-14.923 0-27.067-12.144-27.067-27.066s12.144-27.067 27.067-27.067 27.066 12.144 27.066 27.067-12.144 27.066-27.066 27.066z"
      />
      <g transform="matrix(.90298 0 0 .75602 -929.852 -64.57)">
        <EyePupilSection
          toggle={logoToggle}
          fill="black"
          cx="284.331"
          cy="115.287"
          rx="252"
          ry="252"
          transform="matrix(.12497 0 0 .14389 1110.287 129.393)"
        />
        <LineChartSection
          color="white"
          stroke="green"
          toggle={logoToggle}
          d="M1119.61 166.218a.87.87 0 01-.651-.302 1.12 1.12 0 010-1.457l13.81-15.452c.285-.319.723-.395 1.08-.183l12.958 7.644 6.494-15.412c.129-.309.388-.525.689-.578s.606.064.815.313l5.972 7.128 8.173-19.733c.214-.517.761-.74 1.222-.502s.662.851.448 1.368l-8.746 21.118a.944.944 0 01-.689.585.87.87 0 01-.818-.312l-5.978-7.137-6.327 15.015a.96.96 0 01-.541.538.83.83 0 01-.721-.065l-13.209-7.791-13.33 14.915a.87.87 0 01-.652.302z"
        />
      </g>
    </svg>
  );
}

export default Logo;
