import React, { useState } from "react";
import SteeringIcon from "../../assets/icons/steering";
import { useEventEmitter } from "@visx/xychart";

const SteeringSection = () => {
  const [steering1, setSteering1] = useState(0);
  const [steering2, setSteering2] = useState(0);

  useEventEmitter(
    "pointermove",
    (e) => {
      const { datum } = e.event;
      setSteering1(Math.round(datum.steerAngle));
    },
    ["brake1", "throttle1"]
  );

  useEventEmitter(
    "pointermove",
    (e) => {
      const { datum } = e.event;
      setSteering2(Math.round(datum.steerAngle));
    },
    ["brake2", "throttle2"]
  );

  useEventEmitter(
    "pointerout",
    (e) => {
      setSteering1(0);
      setSteering2(0);
    },
    ["brakechart", "throttlechart"]
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SteeringIcon
            width={48}
            height={48}
            fill="white"
            rotate={steering1}
          />
          <div
            style={{ textAlign: "center", fontSize: 12, userSelect: "none" }}
          >
            steer1
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <SteeringIcon
            width={48}
            height={48}
            fill="orange"
            rotate={steering2}
          />
          <div
            style={{ textAlign: "center", fontSize: 12, userSelect: "none" }}
          >
            steer2
          </div>
        </div>
      </div>
    </>
  );
};

export default SteeringSection;
