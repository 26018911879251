import { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Select,
  message,
  Modal,
  Row,
  Col,
  Grid,
  Upload,
  Button,
  Switch,
  Tooltip,
} from "antd";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { ParentSize } from "@visx/responsive";
import { EventEmitterProvider } from "@visx/xychart";
import BrushChart from "../../components/BrushChart";
import ThrottleBarChart from "../../components/ThrottleBarChart";
import BrakeBarChart from "../../components/BrakeBarChart";
import SteeringSection from "../../components/SteeringSection";
import LineChartD3 from "../../components/LineChartD3";
import LineChartD3Zoom from "../../components/LineChartD3Zoom";
import SpeedoMeterSection from "../../components/SpeedoMeterSection";
import RPMmeterSection from "../../components/RPMmeterSection";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import Logo from "../../assets/logo";
import SetupIcon from "../../assets/icons/SetupIcon";
import Worker from "workerize-loader!../../workers/lapchunksv4.1.worker.js"; // eslint-disable-line import/no-webpack-loader-syntax

import { msToTime } from "../../utils/functions";

import "antd/dist/antd.css";
import "./App.css";
import { menuItems } from "./menuItems";
import AppRoutes from "./AppRoutes";

const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;
const { useBreakpoint } = Grid;

const { Dragger } = Upload;

const worker = new Worker();

const sampleLapData = require("../../utils/mock/laps/nurburgring/nurburgring_porsche_991ii_gt3_r_2023-01-25_22.07");

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  const [collapsed, setCollapsed] = useState(false);
  const [lapsLoading, setLapsLoading] = useState(false);
  const [lap1Val, setLap1Val] = useState("lap0");
  const [lap2Val, setLap2Val] = useState("lap0");
  const [carModel, setCarModel] = useState(null);
  const [trackName, setTrackName] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [dataRecoderVersion, setDataRecoderVersion] = useState(null);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [sectorData, setSectorData] = useState([0, 0]);
  const [totalLaps, setTotalLaps] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const [timeVarianceArr, setTimeVarianceArr] = useState([]);
  const [gaugeMode, setGaugeMode] = useState(true);
  const [logoToggle, setLogoToggle] = useState(false);
  const [setupFile, setSetupFile] = useState(null);
  const [setupFileData, setSetupFileData] = useState(null);
  const [demoMode, setDemoMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(300);
  const [brushDomain, setBrushDomain] = useState({
    start: { x: 0 },
    end: { x: 0 },
  });

  const showGauges = !(
    location.pathname.includes("trackreport") ||
    location.pathname.includes("upcomingfeatures") ||
    location.pathname.includes("racingline")
  );

  // const isXL = screens && screens.xl;
  // const isXS = screens && screens.xs;
  const headerHeight = 65;
  const footerHeight = 65;

  const dynamicHeight = `calc(100vh)`;
  const dynamicContentHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`;

  useEffect(() => {
    setDemoMode(true);
  }, []);

  useEffect(() => {
    if (demoMode) {
      setLap1Val("lap3");
      setLap2Val("lap1");
      setSetupFileData(sampleLapData);
    } else {
      setLap1Val("lap0");
      setLap2Val("lap0");
      setSetupFile(null);
      setSetupFileData(null);
      setTotalLaps([]);
      setData([]);
      setData2([]);
      setFilteredData([]);
      setFilteredData2([]);
      setTimeVarianceArr([]);
    }
  }, [demoMode]);

  useEffect(() => {
    if (setupFile) {
      const { originFileObj } = setupFile;
      let reader = new FileReader();
      reader.readAsText(originFileObj);
      reader.onload = function () {
        try {
          const data = JSON.parse(reader.result);
          setSetupFileData(data);
        } catch (e) {
          message.error(`Invalid or corrupted setup file`);
        }
      };
    }
  }, [setupFile]);

  useEffect(() => {
    if (setupFileData) {
      const { data, carModel, frequency, dataRecoderVersion, track } =
        setupFileData;

      setCarModel(carModel);
      setTrackName(track);
      setDataRecoderVersion(dataRecoderVersion);
      setFrequency(frequency);
      setLapsLoading(true);

      if (worker) {
        worker.calculateChunks(data);

        worker.onmessage = (message) => {
          if (message.data.sectorData) {
            setSectorData(message.data.sectorData);
          }
          if (message.data.chunksArr) {
            setTotalLaps(message.data.chunksArr);
          }
          if (message.data.timeVarianceArr) {
            setTimeVarianceArr(message.data.timeVarianceArr);
          }
        };
      }
    }
  }, [worker, setupFileData]);

  useEffect(() => {
    if (lapsLoading) {
      message.info("Loading lap data, please wait ...");
    } else {
      message.destroy();
    }
  }, [lapsLoading]);

  useEffect(() => {
    if (totalLaps && totalLaps.length > 0) {
      const index1 = parseInt(lap1Val.replace("lap", ""));
      const index2 = parseInt(lap2Val.replace("lap", ""));
      let arr1 = totalLaps[index1];
      let arr2 = totalLaps[index2];

      worker.calculateTimeVariance(arr1, arr2);

      if (arr1 && arr1.length > 0) {
        setData(arr1);
        const endIndex = Math.round((arr1.length - 1) / 3);
        setFilteredData(arr1.slice(0, endIndex));
      }

      if (arr2 && arr2.length > 0) {
        setData2(arr2);
        const endIndex = Math.round((arr2.length - 1) / 3);
        setFilteredData2(arr2.slice(0, endIndex));
      }

      setLapsLoading(false);
    }
  }, [totalLaps.length]);

  const handleBrushChange = (domain) => {
    if (!domain) return;

    const { x0, x1 } = domain;

    setBrushDomain({
      start: { x: x0 },
      end: { x: x1 },
    });

    const filteredData = data.filter((d) => {
      const x = d.distance;
      return x > Math.round(x0) && x < Math.round(x1);
    });

    const filtered2Data = data2.filter((d) => {
      const x = d.distance;
      return x > Math.round(x0) && x < Math.round(x1);
    });

    worker.calculateTimeVariance(filteredData, filtered2Data);

    setTimeout(() => {
      // TODO: Adjust zoom factor based on brush total length
      let indexPoint = data.findIndex((d) => {
        const roundedx0 = Math.round(x0);
        const roundedx1 = Math.round(x1);
        return d.distance === roundedx1;
      });
      if (indexPoint !== -1) {
        setCurrentDataIndex(indexPoint);
      }
      setFilteredData(filteredData);
      setFilteredData2(filtered2Data);
    }, 250);
  };

  const handleChangeLap1 = (value) => {
    setLap1Val(value);

    const index1 = parseInt(value.replace("lap", ""));
    const index2 = parseInt(lap2Val.replace("lap", ""));
    const arr1 = totalLaps[index1];
    const arr2 = totalLaps[index2];

    worker.calculateTimeVariance(arr1, arr2);

    if (arr1 && arr1.length > 0) {
      setData(arr1);
      const endIndex = Math.round((arr1.length - 1) / 3);
      setFilteredData(arr1.slice(0, endIndex));
    }
    if (arr2 && arr2.length > 0) {
      setData2(arr2);
      const endIndex = Math.round((arr2.length - 1) / 3);
      setFilteredData2(arr2.slice(0, endIndex));
    }
  };

  const handleChangeLap2 = (value) => {
    setLap2Val(value);
    const index1 = parseInt(lap1Val.replace("lap", ""));
    const index2 = parseInt(value.replace("lap", ""));
    const arr1 = totalLaps[index1];
    const arr2 = totalLaps[index2];

    worker.calculateTimeVariance(arr1, arr2);

    if (arr1 && arr1.length > 0) {
      setData(arr1);
      const endIndex = Math.round((arr1.length - 1) / 3);
      setFilteredData(arr1.slice(0, endIndex));
    }

    if (arr2 && arr2.length > 0) {
      setData2(arr2);
      const endIndex = Math.round((arr2.length - 1) / 3);
      setFilteredData2(arr2.slice(0, endIndex));
    }
  };

  const renderLap1Options = () => {
    let elements;
    if (totalLaps.length > 0) {
      elements = totalLaps.map((lap, index) => {
        const val = `lap${index}`;
        const nextLap = totalLaps[index];
        const lastItem = lap[lap.length - 1];

        let time;
        if (nextLap && nextLap.length > 0 && nextLap[0]) {
          time = nextLap[0].iLastTime;
        } else {
          time = lastItem.time;
        }

        let className;
        if (lastItem.isValidLap) {
          className = "lapvalid";
        } else {
          className = "lapinvalid";
        }

        return (
          <Option className={className} value={val}>{`lap${
            index + 1
          } - ${msToTime(time)}`}</Option>
        );
      });
    } else {
      elements = null;
    }

    return elements;
  };

  const renderLap2Options = () => {
    let elements;
    if (totalLaps.length > 0) {
      elements = totalLaps.map((lap, index) => {
        const val = `lap${index}`;
        const nextLap = totalLaps[index];
        const lastItem = lap[lap.length - 1];

        let time;
        if (nextLap && nextLap.length > 0 && nextLap[0]) {
          time = nextLap[0].iLastTime;
        } else {
          time = lastItem.time;
        }

        let className;
        if (lastItem.isValidLap) {
          className = "lapvalid";
        } else {
          className = "lapinvalid";
        }

        return (
          <Option className={className} value={val}>{`lap${
            index + 1
          } - ${msToTime(time)}`}</Option>
        );
      });
    } else {
      elements = null;
    }

    return elements;
  };

  const handleBeforeUpload = (file, fileList) => {
    return false;
  };

  const handleSetupChange = (info) => {
    console.log("info", info.fileList[0]);
    setSetupFile(info.fileList[0]);
  };

  const handleDummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleSetupRemove = () => {
    setSetupFile(null);
  };

  const handleChangeDemoSwitch = (checked) => {
    setDemoMode(checked);
  };

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
    setSidebarWidth(300);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setSidebarWidth(0);
  };

  const uploadProps = {
    accept: ".json",
    showUploadList: false,
    className: setupFile ? "has-file" : "no-file",
    name: "setup",
    maxCount: 1,
    multiple: false,
    fileList: setupFile ? [setupFile] : [],
    beforeUpload: handleBeforeUpload,
    onChange: handleSetupChange,
    customRequest: handleDummyRequest,
    onRemove: handleSetupRemove,
  };

  return (
    <EventEmitterProvider>
      <Layout>
        <Sider
          breakpoint="lg"
          width={sidebarWidth}
          trigger={null}
          // // trigger={<div onClick={handleClickSidebarButton}>x</div>}
          collapsible
          collapsedWidth={0}
          collapsed={!sidebarOpen}
          onBreakpoint={(broken) => {
            console.log(broken);
            if (broken) {
              setSidebarOpen(false);
              setSidebarWidth(0);
            } else {
              setSidebarOpen(true);
              setSidebarWidth(300);
            }
          }}
          style={{
            background: "#242424",
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div className="widget">
            <div className="widget-content">
              <div className="widget-title">Welcome</div>
              <p>
                ACCLapinsights is a web based telemetry app that displays car
                and track data in the form of interactive and easier to read
                charts and visualizations.
              </p>
            </div>
          </div>
          <div className="widget">
            <div
              className="widget-content"
              style={{ minHeight: "auto", padding: 16 }}
            >
              <div className="widget-title">Data</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>DemoMode:</span>{" "}
                  <span>
                    <Switch
                      disabled
                      style={{ marginLeft: 8 }}
                      checked={demoMode}
                      onChange={handleChangeDemoSwitch}
                    />
                  </span>
                </div>
                {!demoMode && (
                  <>
                    <p
                      style={{
                        marginTop: 32,
                        textAlign: "center",
                        wordBreak: "break-word",
                      }}
                    >
                      To generate and view own laps, it requires few simple
                      steps
                      <ol style={{ marginTop: 16 }}>
                        <li>
                          <a href="https://acclapinsights.com/LapinsightsDatarecorder-V1.2.5-beta.zip">
                            Download{" "}
                          </a>{" "}
                          the DataRecorder
                        </li>
                        <li>
                          Run the DataRecorder and drive few laps. It creates
                          data file inside
                          /Documents/AssetoCorsaCompetizone/LapInsights folder
                        </li>
                        <li>Once file is generated, Upload it to the tool.</li>
                      </ol>
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Upload {...uploadProps}>
                        <Button disabled={demoMode} icon={<UploadOutlined />}>
                          Upload File
                        </Button>
                      </Upload>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="widget">
            <div
              className="widget-content"
              style={{ minHeight: "auto", padding: 16 }}
            >
              <div className="widget-title">Laps</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 18,
                }}
              >
                <Select
                  size="small"
                  style={{ width: 150 }}
                  placeholder="Select Lap1"
                  onChange={handleChangeLap1}
                  value={lap1Val}
                >
                  {renderLap1Options()}
                </Select>
                <Select
                  size="small"
                  style={{ width: 150, marginTop: 8 }}
                  placeholder="Select Lap2"
                  onChange={handleChangeLap2}
                  value={lap2Val}
                >
                  {renderLap2Options()}
                </Select>
                <ul
                  style={{
                    marginTop: 16,
                    marginBottom: 0,
                    listStyleType: "none",
                    paddingInlineStart: 16,
                  }}
                >
                  <li> Track: {trackName}</li>
                  <li> Car: {carModel}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <div className="widget-title">Track: {trackName}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LineChartD3
                  data={data}
                  selectedData={showGauges ? filteredData : []}
                  width={170}
                  height={170}
                />
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <div className="widget-title">Gauges</div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SpeedoMeterSection />
                  <RPMmeterSection />
                </div> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 16,
                  }}
                >
                  <BrakeBarChart />
                </div>
                <SteeringSection />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ThrottleBarChart />
                </div>
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <div className="widget-title">Lap1</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                {data2 && data2.length > 0 && (
                  <LineChartD3Zoom
                    id="data1"
                    width={250}
                    height={170}
                    currentDataIndex={currentDataIndex}
                    data={data}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <div className="widget-title">Lap2</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                {data2 && data2.length > 0 && (
                  <LineChartD3Zoom
                    id="data2"
                    width={250}
                    height={170}
                    currentDataIndex={currentDataIndex}
                    data={data2}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <div className="widget-title">Contact</div>
              <p style={{ textAlign: "center" }}>
                Contact me at Raja#0377 or rajavn6789@gmail.com.
              </p>
              <p style={{ textAlign: "center" }}>
                <Link to={"/privacypolicy"}>PrivacyPolicy</Link>{" "}
              </p>
              <p style={{ textAlign: "center", marginTop: -8 }}>
                <Link to={"/termsandconditions"}>TermsAndConditions</Link>
              </p>
            </div>
          </div>
        </Sider>
        <Layout style={{ marginLeft: sidebarWidth }}>
          <Header
            style={{
              position: "relative",
              zIndex: 99,
              top: 0,
              padding: 0,
              background: "#292c30",
              minHeight: 54,
            }}
          >
            <div
              className="logo-container"
              style={{ cursor: "pointer", maxHeight: headerHeight }}
              onClick={() => navigate("/")}
              onMouseEnter={() => setLogoToggle(true)}
              onMouseLeave={() => setLogoToggle(false)}
            >
              <Logo
                style={{ width: 120, height: 60 }}
                logoToggle={logoToggle}
              />
              <h1>
                ACCLapInsights <span className="version">beta</span>
              </h1>
            </div>
            <Menu
              theme="dark"
              mode="horizontal"
              style={{
                borderBottom: "1px solid grey",
                maxHeight: headerHeight,
              }}
              onClick={({ key }) => {
                navigate(key);
              }}
              selectedKeys={[location.pathname.replace("/", "")]}
              items={menuItems}
            />
          </Header>
          <Content
            className={location.pathname.replace("/", "")}
            style={{
              background: "black",
              position: "relative",
              overflowY: "scroll",
              overflowX: "hidden",
              height: dynamicContentHeight,
            }}
          >
            <Tooltip
              overlayInnerStyle={{
                fontSize: 12,
              }}
              showArrow={false}
              placement="right"
              style={{ color: "white" }}
              title={sidebarOpen ? "close side panel" : "open side panel"}
            >
              <div
                className="expand_collapse_sidebar"
                style={{
                  position: "absolute",
                  top: 80,
                  left: 8,
                  cursor: "pointer",
                  zIndex: 999,
                }}
              >
                <Button
                  className="expand-collapse-btn"
                  onClick={() => {
                    if (sidebarOpen) {
                      handleCloseSidebar();
                    } else {
                      handleOpenSidebar();
                    }
                  }}
                >
                  {sidebarOpen ? "<" : ">"}
                </Button>
              </div>
            </Tooltip>
            {showGauges && (
              <div
                style={{
                  background: "black",
                  height: 50,
                  borderBottom: "1px solid grey",
                }}
              >
                {data.length > 0 && (
                  <ParentSize debounceTime={10}>
                    {(parent) => {
                      return (
                        <BrushChart
                          sectorData={sectorData}
                          trackName={trackName}
                          data={data}
                          height={25}
                          width={parent.width}
                          brushDomain={brushDomain}
                          onBrushChange={handleBrushChange}
                        />
                      );
                    }}
                  </ParentSize>
                )}
              </div>
            )}
            <AppRoutes
              dynamicHeight={dynamicHeight}
              currentDataIndex={currentDataIndex}
              trackName={trackName}
              timeVarianceArr={timeVarianceArr}
              data={data}
              data2={data2}
              filteredData={filteredData}
              filteredData2={filteredData2}
            />
          </Content>
          <Footer
            style={{
              padding: 0,
              height: footerHeight,
              background: "#292c30",
              color: "white",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                height: "100%",
              }}
            >
              <div style={{ margin: 8 }}>
                ©2024 ACCLapInsights | Developed by Raja
              </div>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </EventEmitterProvider>
  );
}

export default App;
