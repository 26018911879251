import React, { useMemo, useState } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { GradientDarkgreenGreen } from "@visx/gradient";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { useEventEmitter } from "@visx/xychart";
import { index } from "d3-array";

const verticalMargin = 48;

// accessors
const getName = (d) => d.name;
const getValue = (d) => d.value;

const ThrottleBarChart = ({ width = 80, height = 120 }) => {
  const [throttle1, setThrottle1] = useState(0);
  const [throttle2, setThrottle2] = useState(0);

  const data = [
    {
      name: "T1",
      value: throttle1,
    },
    {
      name: "T2",
      value: throttle2,
    },
  ];

  useEventEmitter(
    "pointermove",
    (e) => {
      const { datum } = e.event;
      setThrottle1(Math.round(datum.gas));
    },
    ["throttle1", "brake1"]
  );

  useEventEmitter(
    "pointermove",
    (e) => {
      const { datum } = e.event;
      setThrottle2(Math.round(datum.gas));
    },
    ["throttle2", "brake2"]
  );

  useEventEmitter(
    "pointerout",
    (e) => {
      setThrottle1(0);
      setThrottle2(0);
    },
    ["throttlechart", "brakechart"]
  );

  // bounds
  const xMax = width;
  const yMax = height - verticalMargin;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map(getName),
        padding: 0.3,
      }),
    [xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, 100],
      }),
    [yMax]
  );

  const barWidth = xScale.bandwidth();

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <GradientDarkgreenGreen id="darkgreen" />
      <Group top={verticalMargin / 2}>
        <Bar
          key={`${data[0].name}-empty`}
          x={xScale(data[0].name)}
          y={0}
          width={barWidth}
          height={yMax}
          fill="grey"
        />
        <Bar
          key={`${data[1].name}-empty`}
          x={xScale(data[1].name)}
          y={0}
          width={barWidth}
          height={yMax}
          fill="grey"
        />
        {data.map((d) => {
          const name = getName(d);
          const barHeight = yMax - (yScale(getValue(d)) ?? 0);
          const barX = xScale(name);
          const barY = yMax - barHeight;
          const fill = name.includes("1") ? "white" : "green";
          return (
            <Bar
              key={`bar-${name}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={fill}
            />
          );
        })}
        {data.map((d, index) => {
          const name = getName(d);
          const value = getValue(d);
          const barX = xScale(name);
          let fill;
          if (index === 0 && value === 100) {
            fill = "white";
          } else if (index === 1 && value === 100) {
            fill = "green";
          } else {
            fill = "grey";
          }

          return (
            <rect width={barWidth} height={8} x={barX} y={-10} fill={fill} />
          );
        })}
        <AxisBottom
          numTicks={data.length}
          top={yMax}
          scale={xScale}
          tickLabelProps={() => ({
            fill: "white",
            pointerEvents: "none",
            fontSize: 11,
            textAnchor: "middle",
          })}
        />
      </Group>
    </svg>
  );
};

export default ThrottleBarChart;
