import React from "react";

const BrakeDiscIcon = (props) => (
  <svg width="24" height="24" fill="none" viewBox="0 0 48 48" {...props}>
    <path fill="#fff" fill-opacity="0.01" d="M0 0H48V48H0z"></path>
    <circle
      cx="24"
      cy="24"
      r="16"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    ></circle>
    <circle cx="24" cy="24" r="4" fill="white"></circle>
    <path d="M24 4a20 20 0 0120 20h-7.994A12.006 12.006 0 0024 11.994V4z">
      <animate
        attributeName="fill"
        values="white;brown;white"
        dur="6s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

export default BrakeDiscIcon;
