import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const DriverAnalysis = lazy(() => import("../DriverAnalysis"));
const Rpm = lazy(() => import("../Rpm"));
const Chasis = lazy(() => import("../Chasis"));
const Suspension = lazy(() => import("../Suspension"));
const Rake = lazy(() => import("../Rake"));
const Wheel = lazy(() => import("../Wheel"));
const GForcesReport = lazy(() => import("../GForcesReport"));
const Temps = lazy(() => import("../Temps"));
const TrackReport = lazy(() => import("../TrackReport"));
const UpcomingFeatures = lazy(() => import("../UpcomingFeatures"));
const RacingLineReport = lazy(() => import("../RacingLineReport"));
const PrivacyPolicy = lazy(() => import("../PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../TermsAndConditions"));
const FourOhFour = lazy(() => import("../../components/FourOhFour"));

const AppRoutes = ({
  currentDataIndex,
  trackName,
  timeVarianceArr,
  dynamicHeight,
  data,
  data2,
  filteredData,
  filteredData2,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/trackreport" />} />
      <Route
        path="/driveranalysis"
        element={
          <DriverAnalysis
            timeVarianceArr={timeVarianceArr}
            filteredData={filteredData}
            filteredData2={filteredData2}
          />
        }
        exact
      />
      <Route
        path="/rpm"
        element={
          <Rpm filteredData={filteredData} filteredData2={filteredData2} />
        }
        exact
      />
      <Route
        path="/chasis"
        element={
          <Chasis filteredData={filteredData} filteredData2={filteredData2} />
        }
        exact
      />
      <Route
        path="/suspension"
        element={
          <Suspension
            filteredData={filteredData}
            filteredData2={filteredData2}
          />
        }
        exact
      />
      <Route
        path="/rake"
        element={
          <Rake filteredData={filteredData} filteredData2={filteredData2} />
        }
        exact
      />
      <Route
        path="/wheel"
        element={
          <Wheel filteredData={filteredData} filteredData2={filteredData2} />
        }
        exact
      />
      <Route
        path="/temps"
        element={
          <Temps filteredData={filteredData} filteredData2={filteredData2} />
        }
        exact
      />
      <Route
        path="/trackreport"
        element={
          <TrackReport
            data={data}
            data2={data2}
            trackName={trackName}
            currentDataIndex={currentDataIndex}
          />
        }
        exact
      />
      <Route
        path="/racingline"
        element={
          <RacingLineReport
            data={data}
            data2={data2}
            trackName={trackName}
            currentDataIndex={currentDataIndex}
          />
        }
        exact
      />
      <Route
        path="/gforcesreport"
        element={
          <GForcesReport
            data={filteredData}
            data2={filteredData2}
            dynamicHeight={dynamicHeight}
          />
        }
        exact
      />
      <Route path="/upcomingfeatures" element={<UpcomingFeatures />} exact />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} exact />
      <Route
        path="/termsandconditions"
        element={<TermsAndConditions />}
        exact
      />
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
};

export default AppRoutes;
