const trackTurnsData = {
  laguna_seca: {
    turns: [180, 480, 770, 1050, 1550, 1950, 2400, 2460, 2700, 2950, 3240],
    sectors: [900, 2250],
  },
  zolder: {
    turns: [280, 600, 830, 1150, 1730, 2050, 2300, 2540, 3000, 3150, 3600],
    sectors: [1450, 2800],
  },
  misano: {
    turns: [
      270, 330, 390, 640, 800, 950, 1300, 1690, 2130, 2210, 2744, 3019, 3176,
      3450, 3720, 3850,
    ],
    sectors: [925, 2600],
  },
  nurburgring: {
    turns: [
      650, 900, 1060, 1150, 1639, 1818, 2440, 2754, 2949, 3352, 3552, 3978,
      4454, 4590, 4776,
    ],
  },
  imola: {
    turns: [
      760, 1290, 1400, 1690, 1730, 2250, 3300, 3350, 3750, 4030, 4280, 4680,
    ],
  },
  silverstone: {
    turns: [
      380, 958, 1108, 1244, 1453, 2243, 2793, 2856, 3005, 3499, 3729, 4016,
      4171, 4371, 5080, 5300,
    ],
  },
};

export const getTurns = (track) => {
  let turns;
  if (!track) {
    turns = [];
  } else if (trackTurnsData[track.toLowerCase()]) {
    turns = trackTurnsData[track.toLowerCase()].turns;
  } else {
    turns = [];
  }
  return turns;
};

export const getSectors = (track) => {
  let sectors;
  if (!track) {
    sectors = [];
  } else if (trackTurnsData[track.toLowerCase()]) {
    sectors = trackTurnsData[track.toLowerCase()].sectors;
  } else {
    sectors = [];
  }
  return sectors;
};
