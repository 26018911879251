import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useEventEmitter } from "@visx/xychart";
import * as d3 from "d3";

const LineChartD3 = ({ data, selectedData, height = 400, width = 400 }) => {
  const margin = { top: 8, right: 8, bottom: 8, left: 8 };

  const [distance, setDistance] = useState(0);

  const boundedWidth = width - margin.left - margin.right;
  const boundedHeight = height - margin.top - margin.bottom;

  const xAccessor = (d) => d.carCoordinates[2];
  const yAccessor = (d) => d.carCoordinates[0];

  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain(d3.extent(data, yAccessor))
      .range([boundedHeight, 0]);
  }, [data]);

  const xScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain(d3.extent(data, xAccessor))
      .range([0, boundedWidth]);
  }, [data]);

  const lineGenerator = d3
    .line()
    .x((d) => xScale(xAccessor(d)))
    .y((d) => yScale(yAccessor(d)));

  useEventEmitter(
    "pointermove",
    (e) => {
      setDistance(e.event.datum.distance);
    },
    ["brake1", "throttle1"]
  );

  useEventEmitter(
    "pointerout",
    (e) => {
      setDistance(0);
    },
    ["brakechart", "throttlechart"]
  );

  useLayoutEffect(() => {
    const trackPositionGroup = d3.select("#simple-track-tooltip-position");
    if (distance) {
      //cleanup
      trackPositionGroup.selectAll("circle").remove();

      // TODO: Improve filter performance for 4000+ items
      // add circle
      trackPositionGroup
        .selectAll("circle")
        .data(selectedData.filter((d) => d.distance === distance))
        .join("circle")
        .attr("r", "3")
        .attr("fill", "red")
        .attr("cy", (d) => yScale(yAccessor(d)))
        .attr("cx", (d) => xScale(xAccessor(d)));
    } else {
      trackPositionGroup.selectAll("circle").remove();
    }
  }, [distance]);

  const linePath = lineGenerator(data);
  const selectedLinePath = lineGenerator(selectedData);
  if (!linePath) {
    return null;
  }

  return (
    <svg
      id={"simple-track"}
      width={width}
      height={height}
      style={{ pointerEvents: "none" }}
    >
      <g
        id="simple-track-path"
        width={boundedWidth}
        height={boundedHeight}
        transform={`translate(${[margin.left, margin.top].join(",")})`}
      >
        <path d={linePath} fill="none" stroke="#c4c4c4" strokeWidth={2} />
      </g>
      <g
        id="simple-track-selectedpath"
        width={boundedWidth}
        height={boundedHeight}
        transform={`translate(${[margin.left, margin.top].join(",")})`}
      >
        <path d={selectedLinePath} fill="none" stroke="green" strokeWidth={3} />
      </g>
      <g
        id="simple-track-tooltip-position"
        width={boundedWidth}
        height={boundedHeight}
        transform={`translate(${[margin.left, margin.top].join(",")})`}
      ></g>
    </svg>
  );
};

export default memo(LineChartD3);
