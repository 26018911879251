import Icon, {
  LineChartOutlined,
  BarsOutlined,
  YuqueOutlined,
  CarOutlined,
  DotChartOutlined,
} from "@ant-design/icons";

import SuspensionIcon from "../../assets/icons/suspension";
import WheelIcon from "../../assets/icons/wheel";
import BrakeDiscIcon from "../../assets/icons/brakedisc";

export const menuItems = [
  {
    label: (
      <span>
        DriverAnalysis <span class="menu_sub_label"></span>
      </span>
    ),
    key: "driveranalysis",
    icon: <LineChartOutlined />,
  },

  {
    label: (
      <span>
        RPM <span class="menu_sub_label"></span>
      </span>
    ),
    key: "rpm",
    icon: (
      <Icon
        spin
        component={WheelIcon}
        style={{ fontSize: 24, verticalAlign: "middle" }}
      />
    ),
  },
  {
    label: (
      <span>
        Wheel <span class="menu_sub_label"></span>
      </span>
    ),
    key: "wheel",
    icon: (
      <Icon
        spin
        component={WheelIcon}
        style={{ fontSize: 24, verticalAlign: "middle" }}
      />
    ),
  },
  {
    label: (
      <span>
        Temps <span class="menu_sub_label"></span>
      </span>
    ),
    key: "temps",
    icon: (
      <BrakeDiscIcon
        width={20}
        height={20}
        style={{ verticalAlign: "middle" }}
      />
    ),
  },

  {
    label: (
      <span>
        Chasis <span class="menu_sub_label"></span>
      </span>
    ),
    key: "chasis",
    icon: <CarOutlined className={"carchasis"} style={{ fontSize: 14 }} />,
  },
  {
    label: (
      <span>
        Suspension <span class="menu_sub_label"></span>
      </span>
    ),
    key: "suspension",
    icon: (
      <SuspensionIcon
        className={"suspension"}
        style={{ verticalAlign: "middle" }}
      />
    ),
  },
  {
    label: (
      <span>
        Rake <span class="menu_sub_label"></span>
      </span>
    ),
    key: "rake",
    icon: <CarOutlined className={"carchasis"} style={{ fontSize: 14 }} />,
    // icon: (
    //   <SuspensionIcon
    //     className={"suspension"}
    //     style={{ verticalAlign: "middle" }}
    //   />
    // ),
  },
  {
    label: (
      <span>
        Track Report <span class="menu_sub_label">beta</span>
      </span>
    ),
    key: "trackreport",
    icon: <YuqueOutlined />,
  },
  {
    label: (
      <span>
        GForce Report <span class="menu_sub_label"></span>
      </span>
    ),
    key: "gforcesreport",
    icon: <DotChartOutlined />,
  },

  // {
  //   label: (
  //     <span>
  //       Racing Line <span class="menu_sub_label"></span>
  //     </span>
  //   ),
  //   disabled: true,
  //   key: "racingline",
  //   icon: <YuqueOutlined />,
  // },

  // {
  //   label: (
  //     <span>
  //       Upcoming Features <span class="menu_sub_label"></span>
  //     </span>
  //   ),
  //   key: "upcomingfeatures",
  //   icon: <BarsOutlined />,
  // },
];
