import React from "react";

const SteeringIcon = ({ width, height, fill, rotate = 0 }) => (
  <svg viewBox="0 0 512 512" width={width} height={height} fill={fill}>
    <g transform={`rotate(${rotate} 256 256)`}>
      <path
        d="M247.442 46.558C151.478 50.245 68.662 114.97 41.902 207.2c-26.76 92.23 8.55 191.23 87.636 245.708a71.135 71.135 0 0 0 40.353 12.553h172.218a71.134 71.134 0 0 0 40.353-12.553C461.549 398.43 496.859 299.43 470.098 207.2c-26.76-92.23-109.576-156.956-205.54-160.643-6.346-.006-10.914-.042-17.116 0zm7.895 52.812h.014c.446.037.883.018 1.298 0h.014c76.352.298 143.136 51.479 163.273 125.128-.641.176-.642.176 0 0 1.293 4.73-2.277 9.406-7.18 9.406H99.244c-4.902 0-8.472-4.677-7.18-9.405.643.175.642.175 0-.001 20.138-73.65 86.922-124.83 163.274-125.128zM94.915 284.446a7.51 7.51 0 0 1 1.883.208c60.338 14.447 108.882 59.128 128.27 118.064 1.581 4.806-2.011 9.77-7.07 9.77H169.89a18.158 18.158 0 0 1-10.301-3.204 169.955 169.955 0 0 1-71.896-116.358c-.636-4.537 2.913-8.402 7.221-8.48zm322.17 0c4.308.079 7.857 3.943 7.22 8.48a169.955 169.955 0 0 1-71.895 116.358 18.158 18.158 0 0 1-10.3 3.204h-48.107c-5.06 0-8.652-4.964-7.071-9.77 19.388-58.936 67.932-103.617 128.27-118.064a7.493 7.493 0 0 1 1.883-.208z"
        overflow="visible"
      />
      {/* <circle cx={256} cy={256 + 40} fill="black" r={12} /> */}
    </g>
  </svg>
);

export default SteeringIcon;
